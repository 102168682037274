$(document).ready(function () {

    var types = getCheckedValues('type'),
        organisers = getCheckedValues('organiser'),
        locations = getCheckedValues('location'),
        dates = getCheckedValues('date'),
        filter_event = $('#filter-event'),
        base_url = filter_event.first().data('url')
    ;

    // console.log('hello');

    // Listen for 'change' event, so this triggers when the user clicks on the checkboxes labels
    $('.filter-input').on('change', function (e)
    {
        e.preventDefault();
        var filter = this.dataset.filter;

        eval(filter + "s = []");

        if($(this).val() === '') {
            $('.filter-input.filter-input-' + filter + '[data-all="false"]').each(function () {
                $(this).prop("checked", false);
            });
        } else {
            $('.filter-input.filter-input-' + filter + '[data-all="true"]').prop('checked', false);

            $('.filter-input.filter-input-' + filter + ':checked').each(function()
            {
                eval(filter + "s.push(" + $(this).val() + ")");
            });
        }

        filter_event.html();

        $.get(base_url, {
            categories: types,
            locations: locations,
            organisers: organisers,
            status: dates
        }, function(data)
        {
            filter_event.html(data.html);

            if (data.links !== null && data.links.next_page_url) {
                filter_event.data('url', data.links.next_page_url);
            }
        });
    });
});

function getCheckedValues(filter) {
    var array = [];
    $('.filter-input.filter-input-' + filter + ':checked').each(function()
    {
        array.push($(this).val());
    });
    return array;
}
// function getParameterByName(name, url = window.location.href) {
//     name = name.replace(/[\[\]]/g, '\\$&');
//     var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//         results = regex.exec(url);
//     if (!results) return null;
//     if (!results[2]) return '';
//     return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }
