const toastr_container = document.querySelector("#toastr_loader");

if (toastr_container) {
    const dataset = toastr_container.dataset;

    var toastr_conifg = JSON.parse(dataset.toastr);
    var param = jQuery.extend(
        {
            type: null,
            title: null,
            message: dataset.message,
            options: null
        },
        toastr_conifg
    );

    if (toastr_conifg != null) {
        if (window.toastr !== undefined) {
            toastr.options = {
                closeButton: true,
                newestOnTop: true,
                progressBar: true
            };

            if (param.options != null) {
                $.extend(toastr.options, param.options);
            }

            if (param.type === "warning") {
                toastr.warning(param.message, param.title);
            }

            if (param.type === "success") {
                toastr.success(param.message, param.title);
            }

            if (param.type === "error") {
                toastr.error(param.message, param.title);
            }

            if (param.type === "info") {
                toastr.info(param.message, param.title);
            }

            $("#toast-container.toast-bottom-full-width > div").css(
                "max-width",
                "100vw"
            );
        }
    }
}
