/**
 * Append loader to page
 *
 * @param {string} className Loader class name
 */
export const appendLoader = className => {
    const loader = document.createElement("div");
    loader.setAttribute("id", "overlay2");
    loader.className = `loader custom__loader ${className}`;
    const loaderHTML = `
    <div class="row">
        <div class="ajax-pre-loader"></div>
    </div>`;

    loader.innerHTML = loaderHTML;

    const body = document.querySelector("body");

    if (body) {
        body.append(loader);
    }
};

/**
 * Remove loader to page
 *
 * @param {string} className Loader class name
 */
export const removeLoader = className => {
    const loader = document.querySelector(`.${className}`);

    if (loader) {
        loader.classList.add("custom__loader--close");

        setTimeout(() => {
            loader.remove();
        }, 300);
    }
};
