$(document).ready(function () {

    $('#menu-main-menu .active').parents('li').last().addClass('active');

    $('#menu-main-menu li').each(function() {
        if ($(this).find('ul').length) {
            $(this).addClass('menu-item-has-children');
        }
    });

    $('#menu-main-menu li:has(ul)').find('> ul').addClass('sub-menu');
    $('ul.sub-menu li:has(ul)').find('> ul').addClass('third-level');
    $('ul.sub-menu').find('> li').addClass('sub-menu-item dropdown-item');

    $('.owl-carousel.hero-owl').owlCarousel({
        loop: true,
        // margin: 10,
        nav: true,
        dots:false,
        items: 1,
        autoplay: true,
        autoplayHoverPause: true,
        navText : ["<i class='fas fa-arrow-left'></i>","<i class='fas fa-arrow-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    });

    $('.owl-carousel.projects-owl').owlCarousel({
        loop: true,
        rewind: true,
        scrollPerPage : true,
        slideBy: 2,
        // mouseDrag: false,
        mouseDrag: true,
        margin: 90,
        nav: true,
        dots:false,
        items: 2,
        navText :["<i class='fas fa-arrow-left'></i>","<i class='fas fa-arrow-right'></i>"],
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1,
            },
            1000: {
                items: 2
            }
        }
    });

    $('.owl-carousel.single-course-gallery-owl').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        items: 3,
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 2
            },
            767: {
                items: 3
            },
        }
    });

    $('.course-activities-owl.owl-carousel').owlCarousel({
        loop: false,
        margin: 30,
        nav: false,
        items: 4,
        // navText : ["<i class='fas fa-arrow-left'></i>","<i class='fas fa-arrow-right'></i>"],
        // dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 4
            }
        }
    });

    var owl = $('.owl-carousel.single-hall-gallery-owl');
    owl.owlCarousel({
        loop: true,
        clone: false,
        items:3,
        nav:true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 1
            },
            1000: {
                items: 3
            }
        }
    });

    $('.item').on('click', function(event){
        var $this = $(this);
        $('.owl-preview img').replaceWith($this.html());
    });

    $('.department-owl.owl-carousel').owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        items: 4,
        responsive: {
            0: {
                items: 1
            },
            500: {
                items: 2
            },
            992: {
                items: 4
            }
        }
    });

    $('.events-owl.owl-carousel').owlCarousel({
        loop: true,
        margin: 25,
        nav: false,
        dots: false,
        items: 1,
        responsive: {
            0: {
                items: 1
            },
            650: {
                items: 2
            },
            992: {
                items: 1
            }
        }
    });

    $('.mobile-menu-btn').on('click', function () {
        $('.mobile_menu_wrapper').toggleClass('d-block');
    });

    $('.mobile-menu-btn .menu-item-has-children > a ').on('click', function () {
        $('.menu-item-has-children > ul.sub-menu').toggleClass('d-block');
    });

    $("#back-to-top").click(function () {
        $("html, body").animate({scrollTop: 0}, 500);
    });

    $('.global-search-trigger').on('click', function () {
        $('#overlay').addClass('d-flex');
    });

    $('#overlay').click(function(event) {
        if (!$(event.target).closest(".overlay-search-form").length) {
            $('body').find("#overlay").removeClass('d-flex');
        }
    });

    $("input[name='group_radio']").change(function(){
        if($("input[name='group_radio']:checked").val() === 'Yes')
        {
            $('.nr-seats').addClass('d-block');
        }
        else
        {
            $('.nr-seats').removeClass('d-block');
        }
    })
    //
    // $("input[type='file']").change(function () {
    //     $('#file-filename').text(this.value.replace(/C:\\fakepath\\/i, ''))
    // })

    $('.open-filter').on('click', function () {
        $('.filter-container').addClass('d-block');
        $('.close-filter').addClass('d-inline-block');
        $(this).addClass('d-none');
    })

    $('.close-filter').on('click', function () {
        $('.filter-container').removeClass('d-block');
        $('.open-filter').removeClass('d-none');
        $(this).removeClass('d-inline-block');
    })

});

"use strict";

