let $ = require("jquery");
window.jQuery = $;
window.$ = $;

window.Popper = require("popper.js").default;
window.toastr = require("toastr");

toastr.options = {
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: false,
    positionClass: "toast-top-right",
    onclick: null,
    showDuration: "500",
    hideDuration: "1000",
    timeOut: "5000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

require("./utils/toastr");
require("owl.carousel");
require("bootstrap");
require("dropzone");
require("cookieconsent");
require("./config");
require("./utils/owl")
require("./utils/scrollTo");
require("./map");
require("./custom");
require("./page-loader");
require("./ajax-paginate");
require("./subscribe");
require("./dropzone");
require("./filter");
require("./enrollment/book");
require("./hall/book");
require("./event/book");
require("./event/manualBooking");
require("./dropdownmenu");
require("./add-class");
require("./cookie-consent");
