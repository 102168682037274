import { loadStripe } from "@stripe/stripe-js";
import { appendLoader, removeLoader } from "../utils/loader"

if($("#enrollmentForm").length) {

    const form = $("#enrollmentForm"),
        submitButton = document.getElementById("enroll");
    const formId = form[0].dataset.courseId;

    getFormData();

    $("#enrollment_type_id").change(function () {
        updateSummary();
    });

    $("#enrollmentForm :input").change(function () {
        $('span.error[for="' + this.name + '"]').html('').hide();
        setFormData();
    });

    $("#is_band-dropdownMenuButton").on('click', function () {
       $('span.error[for="is_band"]').html('').hide();
    });

    $("#bio").change(function () {
        // updateSummary();
        updateFileName()
    });

    $("#terms").change(function () {
        $("#accept_terms").val($("#terms:checked").length === 1);
        setFormData()
    });

    $("input[name=payment_method]").change(function () {
        $("#payment_method").val(
            $("input:radio[name=payment_method]:checked").val()
        );
    });

    function setFormData() {
        let formdata = form.serializeArray().filter((input)=>{
            if (input.name !== "_token"){
                return input
            }
        });

        localStorage.setItem(`enrollment_form_${formId}`, JSON.stringify(formdata));
    }

    function getFormData() {
        const savedEnrollmentForm = localStorage.getItem(`enrollment_form_${formId}`)

        if (!savedEnrollmentForm){
            return
        }

        let formdata = JSON.parse(savedEnrollmentForm);

        for (const field of formdata) {
            switch (field.name) {
                case "payment_method":
                    document.querySelector(
                        `input[value="${field.value}"]`
                    ).checked = true;
                    break;
                    case "accept_terms":
                    document.querySelector("#terms").checked = field.value === "true";
                    break;
            }

            if (document.getElementById(field.name)) {
                document.getElementById(field.name).value = field.value;
            }
        }
    }

    function updateSummary() {
        const enrollmentForm = document.querySelector("#enrollmentForm");

        if (!enrollmentForm) {
            return;
        }

        const savedEnrollmentForm = localStorage.getItem(`enrollment_form_${formId}`)

        const registrationSummary = document.querySelector("#registration_summary");
        const registrationSummaryTitle = registrationSummary.querySelector(
            ".selected-subscription__head h3.registration__summary__title"
        );
        const registrationSummaryPrice = registrationSummary.querySelector(
            ".selected-subscription__body .registration__summary__price"
        );
        const registrationSummaryTotal = registrationSummary.querySelector(
            "#grand-total .registration__summary__total"
        );

        const enrollmentType = document.querySelector(
            "#enrollmentForm #enrollment_type"
        );
        const enrollmentInput = enrollmentType.querySelector("input");
        const enrollmentButton = enrollmentType.querySelector("button");
        const enrollmentMenu = enrollmentType.querySelector(".dropdown-menu");

        let selectedPrice = null;

        enrollmentMenu.querySelectorAll(".dropdown-item").forEach((item,index) => {
            const itemData = JSON.parse(item.dataset.info);

            if (index === 0){
                selectedPrice = itemData.id;
            }

            if (savedEnrollmentForm) {
                let formdata = JSON.parse(savedEnrollmentForm);

                for (const field of formdata) {
                    switch (field.name) {
                        case "enrollment_type_id":
                            selectedPrice = field.value
                            break;
                    }
                }
            }

            const updateSummaryContent = () => {
                enrollmentButton.innerHTML = `<span>${itemData.name} — </span><strong>${itemData.cost_label}</strong>`;
                enrollmentInput.value = itemData.id;
                registrationSummaryTitle.textContent = itemData.name;
                registrationSummaryPrice.textContent = itemData.cost_label;
                registrationSummaryTotal.textContent = itemData.cost_label;

                if(itemData.cost === '0') {
                    document.getElementById('payment_method__items').style.display = 'none'
                } else {
                    document.getElementById('payment_method__items').style.display = 'unset'
                }
            }

            if (itemData.id === selectedPrice) {
                updateSummaryContent()
            }

            item.addEventListener("click", el => {
                updateSummaryContent()
                setFormData()
            });
        });

        const isBand = document.querySelector("#enrollmentForm #is_band");
        const isBandInput = isBand.querySelector("input");
        const isBandButton = isBand.querySelector("button");
        const isBandMenu = isBand.querySelector(".dropdown-menu");


        let isBandValue = "0";
        let groupValue = "1";

        if (savedEnrollmentForm) {
            let formdata = JSON.parse(savedEnrollmentForm);

            for (const field of formdata) {
                switch (field.name) {
                    case "is_band":
                        isBandValue = field.value
                        break;
                    case "seats":
                        groupValue = field.value
                        break;
                }
            }
        }

        isBandMenu.querySelectorAll(".dropdown-item").forEach((item, index) => {
            const itemData = JSON.parse(item.dataset.info);

            if (itemData.value === isBandValue) {
                isBandButton.innerHTML = `<span>${itemData.text}</span>`;
                isBandInput.value = itemData.value;

                toggleSeatsNo(itemData.value, groupValue);
            }

            item.addEventListener("click", el => {
                isBandButton.innerHTML = `<span>${itemData.text}</span>`;
                isBandInput.value = itemData.value;

                toggleSeatsNo(itemData.value);

                setFormData()
            });
        });

        const payment_method = document.querySelector("#payment_method");

        document.querySelectorAll("input.payment-type").forEach((item, index) => {
            if (item.checked) {
                payment_method.value = item.value;
                payment_method.dispatchEvent(new Event("change"));
            }

            item.addEventListener("click", el => {
                payment_method.value = item.value;
                payment_method.dispatchEvent(new Event("change"));
            });
        });
    }

    function toggleSeatsNo(is_band, groupValue) {
        if (parseInt(is_band, 10) === 0) {
            $("#seats")
                .val(1)
                .parent()
                .hide();
        } else {
            $("#seats")
                .val(groupValue || 1)
                .parent()
                .show();
        }
    }

    function updateFileName() {
        document.getElementById("filename").innerText = document.getElementById(
            "bio"
        ).files[0].name;
    }

    $(window).on("load", function () {
        updateSummary();

        submitButton.addEventListener("click", el => {
            form.trigger('submit');
        });
    });


    $(document).on("submit", "#enrollmentForm", function(event)
    {
        event.preventDefault();
        form.find('span.error').html('').hide();
        const stripe = Stripe(process.env.MIX_STRIPE_KEY);
        const loaderClassName = "enrollment__loader";
        appendLoader(loaderClassName);

        $.ajax({
            url: this.action,
            type: this.method,
            dataType: 'JSON',
            contentType: false,
            processData: false,
            data: new FormData(this),
            error: function (jqXHR, statusText, error) {
                console.log(jqXHR.responseJSON);
                removeLoader(loaderClassName);

                if([400, 401, 402, 403].indexOf(jqXHR.status) != -1){
                    toastr.error(form.first().data('unauthenticated'));
                } else if(jqXHR.status === 422){
                    $.each(jqXHR.responseJSON, function (key, message){
                        if(message.length === 1){
                            $('span.error[for="' + key + '"]').html(message).show();
                        } else {
                            $('span.error[for="' + key + '"]').html(message.join("\n")).show();
                        }
                    });
                } else {
                    toastr.error(jqXHR.responseJSON.message)
                }
            },
            success: function (response) {
                removeLoader(loaderClassName);
                if(response.message) {
                    toastr.success(response.message)
                } else if(response.id) {

                    return stripe.redirectToCheckout({ sessionId: response.id });
                }

                if(response.redirect){
                    setTimeout(function () {
                        window.location.href = response.redirect;
                    }, 3000);
                }
            },
        });
    });
}
