const dropdownMenuButton = document.querySelector(".navbar-dropdown-button");
const dropdownMenu = document.querySelector(".dropdown-menu-items");
const closeDropdownMenu = document.querySelector(".close-dropdown-menu")


dropdownMenuButton.addEventListener("click",()=>{
    dropdownMenu.classList.toggle("show")
})

closeDropdownMenu.addEventListener("click",()=>{
    dropdownMenu.classList.toggle("show")
})
