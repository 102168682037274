import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from '@analytics/google-analytics'

$(document).ready(function () {
    const trackPage = (tagManagerId, analyticsId, appName = "fossano") => {
        const plugins = [];

        if (tagManagerId && tagManagerId !== ""){
            plugins.push(
                googleTagManager({
                    containerId:tagManagerId
                })
            )
        }

        if (analyticsId && analyticsId !== ""){
            plugins.push(
                googleAnalytics({
                    trackingId: analyticsId
                })
            )
        }

        if (plugins.length === 0) {
            return;
        }

        const analytics = Analytics({
            app:appName,
            plugins
        })

        analytics.page()
    }

    const getCookie = (name) => {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }

    const cookieContainerEl = document.getElementById("cookie-consent");
    const content = JSON.parse(cookieContainerEl.dataset.content);
    const cookie = JSON.parse(cookieContainerEl.dataset.cookie);
    const googleTagManagerId = document.querySelector(`meta[name="google_tag_manager_id"]`).getAttribute("content");
    const googleAnalyticsId = document.querySelector(`meta[name="google_analytics_tracking_id"]`).getAttribute("content");

    const acceptedCookie = getCookie("cookieconsent_status");

    if(acceptedCookie){
        trackPage(googleTagManagerId, googleAnalyticsId)
    }

    cookieconsent.initialise({
        container: cookieContainerEl,
        revokable:true,
        onStatusChange: function(status) {
            // const userAcceptedCookies= this.hasConsented();
            trackPage(googleTagManagerId)
        },
        content,
        cookie,
        law: {
            regionalLaw: false,
        },
    });
})
