$(document).ready(function () {

    let loaderHtml = '<div class="col-md-12 ajax-pre-loader-container"><div class="ajax-pre-loader"></div></div>';

    $.each($('.ajax-load'), function (i, element) {
        fetchDataAndAppendToHtml($(this));
    });

    $('body').on('click', '.ajax-load-more', function () {
        let $ajaxLoadElement = $(this).parent().parent('.ajax-load');
        $(this).parent().remove();
        fetchDataAndAppendToHtml($ajaxLoadElement);
    })

    function fetchDataAndAppendToHtml($element) {
        let fetchUrl = $element.data('url');

        if (fetchUrl === undefined || fetchUrl === null) {
            return;
        }

        $.ajax({
            url: fetchUrl,
            type: 'GET',
            dataType: 'JSON',
            beforeSend: function(){
                $element.append(loaderHtml);
            },
            success: function (data) {
                $element.find('div.ajax-pre-loader-container').fadeOut(500, function(){ $(this).remove();});
                setTimeout(function () {
                    $element.append(data.html);
                }, 500);


                if (data.links !== null && data.links.next_page_url) {
                    $element.data('url', data.links.next_page_url);
                }
            }
        });
    }
});
