let all_dropdown_hours = [];



/**
 * Fetches Available Hours based on date.
 *
 * @param {string} Date with format YYYY-MM-DD.
 * @return {object} Object that contains Status, Hours, Error.
 */
export const fetchAvailableHours = async (date) => {
    const hall_id = document.querySelector("input[name=hall_id]").value;
    const url = `/halls/${hall_id}/date/${date}/availableHours`;

    try {
        const res = await fetch(url);
        const hours = await res.json();
        all_dropdown_hours = hours;
        return {
            success: true,
            hours
        }
    } catch (err) {
        return {
            success: false,
            error: err
        }
    }
}

/**
 * Fetches Price based on the Date, Start Time, End Time.
 *
 * @param {string} date with format YYYY-MM-DD.
 * @param {string} startTime.
 * @param {string} endTime.
 * @return {object} Object that contains Status, Hours, Error.
 */
export const fetchHoursPrice = async (date, startTime, endTime) => {
    const hall_id = document.querySelector("input[name=hall_id]").value;
    const url = `/halls/${hall_id}/price/${date}/${startTime}/${endTime}`

    try {
        const res = await fetch(url);
        const price = await res.text();
        return {
            success: true,
            price: parseInt(price, 10)
        }
    } catch (err) {
        return {
            success: false,
            error: err
        }
    }
}


/**
 * Converts Time into number.
 *
 * @param {string} hour the time in format 00:00 .
 * @return {number}.
 */
export const parseTime = (hour) => {
    if (!isNaN(hour)) {
        return
    }

    return parseInt(hour.split(":")[0], 10);
};

/**
 * Add the removed reservation hours to main store
 *
 * @param {string[]} Array of the available hours.
 * @param {string} Start time.
 * @param {string} End time.
 * @return {string[]} Reseted hours.
 */
export const availableHoursReset = (hours, startTime, endTime) => {
    let timeIndex;
    let resetedHours = [...hours];

    if (endTime === "--:00") {
        return hours
    }

    //["6:00", "10:00", "11:00", "12:00", "13:00", "14:00"]
    //["7:00", "8:00", "9:00"]

    //todo find a ray to add the removed hours

    // Loop thru available hours and find the index to add hours
    for (let hour = 0; hour < resetedHours.length; hour++) {
        if (parseTime(startTime) < parseTime(resetedHours[hour])) {
            timeIndex = hour + 1;
            break
        }
    }


    // Hour difference from End Time to Start Time
    const timeDifference = parseTime(endTime) - parseTime(startTime)

    for (let time = 0; time <= timeDifference; time++) {
        const hour = parseTime(endTime) - time;
        resetedHours.splice(timeIndex, 0, `${hour}:00`)
    }


    // console.log(timeIndex)
    // console.log(hours)
    // console.log(resetedHours)

    // Return Reseted hours
    return resetedHours
}

/**
 * Create Dropdown Menu Items
 *
 * @param hours
 * @param parentEl
 * @param onClick
 */
export const createDropdownItems = (hours, parentEl, onClick) => {
    parentEl.innerHTML = ""

    // Create and array of hours, where hours that are available are true
    const allHours = [];

    let first_hour = parseTime(all_dropdown_hours[0]);
    let last_hour = parseTime(all_dropdown_hours[all_dropdown_hours.length - 1]);

    for (let i = first_hour; i < last_hour; i++) {
        allHours[i] = false;
    }

    hours.forEach((hour) => {
        if (!allHours[parseTime(hour)]) {
            allHours[parseTime(hour)] = true;
        }
    });

    allHours.forEach((hour, index) => {
        const item = document.createElement("li");
        item.className = "dropdown-item disabled";
        item.textContent = `${index}:00`

        if (hour) {
            item.classList.remove("disabled")
            item.addEventListener("click", onClick.bind(this));
        }

        parentEl.appendChild(item)
    });
}
