import { parsePrice } from "../utils";
import { appendLoader, removeLoader } from "../utils/loader"
import { loadStripe } from "@stripe/stripe-js";

if($('.event-booking-page').length) {

    $(document).ready(function () {

        $('#credit-card').trigger('click');

        const mainElement = document.querySelector("#map");

        if (!mainElement || !mainElement.dataset.map) {
            return;
        }

        // Payment Method Element
        const paymentMethod = document.querySelector("#payment_method__items")
        // Saved Reservations on localStorage
        const localSavedReservations = () => localStorage.getItem(`map-reservations-${mainElement.dataset.event_id}`)

        const removeFromLocalStorage = () => {
            localStorage.removeItem(`map-reservations-${mainElement.dataset.event_id}`);
        }

        const seatsData = jQuery.parseJSON(mainElement.dataset.map);
        const eventId = mainElement.dataset.event_id;
        const seatsTypes = JSON.parse(mainElement.dataset.ticket_types);
        const local_language = mainElement.dataset.local_language;
        const url_reserved = mainElement.dataset.url_reserved;
        const trans = JSON.parse(mainElement.dataset.trans);

        const L = require("leaflet");

        // finding the center coordinates of the map
        var totalX = 0;
        var totalY = 0;
        var totalNrOfSeats = 0;

        seatsData.features.forEach(element => {
            if(element.geometry.type === 'Point'){
                totalX += parseFloat(element.geometry.coordinates[0]);
                totalY += parseFloat(element.geometry.coordinates[1]);
            } else if (element.geometry.type === 'Polygon'){
                element.geometry.coordinates[0].forEach(coordinatesOfScene => {
                    totalX += parseFloat(coordinatesOfScene[0]);
                    totalY += parseFloat(coordinatesOfScene[1]);
                });
            }
            totalNrOfSeats++;
        });

        var centerX = totalX/totalNrOfSeats;
        var centerY = totalY/totalNrOfSeats;

        let mapMaxResolution = 0.05;
        let mapMinResolution = Math.pow(2, 5) * mapMaxResolution;

        let crs = L.CRS.Simple;

        crs.scale = function(zoom) {
            return Math.pow(2, zoom) / mapMinResolution;
        };
        // crs.zoom = function(scale) {
        //     return Math.log(scale * mapMinResolution) / Math.LN2;
        // };

        // Create map
        const map = L.map("map", {
            center: [centerY, centerX],
            zoom: 1,
            minZoom: 1,
            maxZoom: 7,
            attributionControl: false,
            zoomControl: false,
            zoomDelta: 0.25,
            zoomSnap: 0.25,
            crs: crs
        });


        //Zoom Controls
        const zoomInButton = document.querySelector(".map-zoom-in");
        zoomInButton.addEventListener("click", (el) => {
            map.setZoom(map.getZoom() + 1);
        })

        const zoomOutButton = document.querySelector(".map-zoom-out");
        zoomOutButton.addEventListener("click", (el) => {
            map.setZoom(map.getZoom() - 1)
        })


        // Get current clicked point
        let currentPoint;

        // Cart
        const cart = {};
        // Cart Element
        const cartEl = document.querySelector(".events-booking-seats ul");

        // No Seats Selected El
        const emptySeats = document.querySelector(".events-booking-seats-empty");

        // Proceed Button
        const proceedButton = document.querySelector(".events-booking-seats-proceed-button");
        // Terms and Conditions Checkbox Element
        const termsCheckbox = document.querySelector("#terms")

        const takenSeatColor = "#777777";

        // Get Seat Information
        const getSeat = (seat) => {

            const types = seatsTypes[seat.category];

            if (types) {

                let ticket_type = types[Object.keys(types)[0]];
                let title = ticket_type['name'];
                let description = ticket_type['description'];

                if(ticket_type['translations'] && ticket_type['translations'][local_language] && ticket_type['translations'][local_language].name){
                    title = ticket_type['translations'][local_language].name;
                }

                if(ticket_type['translations'] && ticket_type['translations'][local_language] && ticket_type['translations'][local_language].description){
                    description = ticket_type['translations'][local_language].description;
                }

                return {
                    success: true,
                    data: {
                        id: seat.id,
                        prices: types,
                        title: title,
                        description: description,
                        block: seat.category.toUpperCase(),
                        row: seat.id.split('x')[0],
                        seat: seat.id.split('x')[1]
                    },
                };
            }

            return {
                success: false,
                data: null,
            };
        };

        let paymentMethodVisibility = false;
        // Add to Cart Method
        const addToCart = (seat) => {
            // Save Item To Cart
            cart[seat.id] = {...seat};

            let seatType = trans[seat.prices[0].type];
            let seatDesc = seat.description[0].description;

            for (const key in cart) {
                for (const item of cart[key].prices) {
                    if (item.id === cart[key].priceId){
                        seatType = trans[item.type]
                        seatDesc = item.description

                        if(item.translations && item.translations[local_language] && item.translations[local_language].description){
                            seatDesc = item.translations[local_language].description
                        }

                        if (item.price > 0 && !paymentMethodVisibility){
                            paymentMethodVisibility = true;
                        }
                    }
                }
            }

            paymentMethod.style.display = paymentMethodVisibility ? "block" : "none"

            emptySeats.style.display = "none";
            proceedButton.classList.remove('disabled')

            // Import Cart Item Template Html Element
            const cartItem = document.importNode(
                document.querySelector("#cart-item-template").content,
                true
            );

            cartItem.querySelector(".cart-item").classList.add(`booked-seat-${seat.id}`)
            cartItem.querySelector(".item-title").textContent = seat.title;
            cartItem.querySelector(".item-circle").style.backgroundColor = seat.color;
            cartItem.querySelector(".block").textContent = seat.block;
            cartItem.querySelector(".rows").textContent = seat.row;
            cartItem.querySelector(".place").textContent = seat.seat;
            cartItem.querySelector(".card-item-type").textContent = seatType;
            cartItem.querySelector(".card-item-description").textContent = ' - ' + seatDesc;

            const pricesDropdownButton = cartItem.querySelector(".card-item-price .btn span");
            const pricesDropdownMenu = cartItem.querySelector(".card-item-price .dropdown-menu");

            for (const price of seat.prices) {
                if (price.id === seat.priceId){
                    pricesDropdownButton.textContent = `${parsePrice(price.price)}`;
                }
            }

            seat.prices.forEach((e) => {
                const list_text = document.createElement("p");
                const the_type = e.type;
                let the_description = e.description;
                const the_price = e.price;

                if(e.translations && e.translations[local_language] && e.translations[local_language].description){
                    the_description = e.translations[local_language].description
                }

                list_text.textContent = `${trans[e.type]} - ${the_description} ${parsePrice(e.price).withCurrency('€')}`;
                const li = document.createElement("li");
                li.appendChild(list_text);
                li.classList.add("dropdown-item");
                li.dataset['label'] = trans[e.type];
                li.dataset['id'] = e.id;

                li.addEventListener("click", (el) => {
                    pricesDropdownButton.textContent = `${parsePrice(e.price)}`;
                    cart[seat.id].price = e.price;
                    cart[seat.id].priceId = e.id;

                    el.currentTarget.closest('.card-item-price').querySelector('.card-item-type').textContent = trans[e.type];
                    el.currentTarget.closest('.card-item-price').querySelector('.card-item-description').textContent = ' - ' + e.description;

                    // Update reservation in localStorage
                    if (localSavedReservations()){
                        try {
                            const reservations = JSON.parse(localSavedReservations());
                            reservations.reservations[seat.id].priceId = e.id;
                            localStorage.setItem(`map-reservations-${mainElement.dataset.event_id}`, JSON.stringify(reservations))
                        } catch (err) {
                            console.log(err)

                            // removeFromLocalStorage()
                            console.error("Saved Reservation on localStorage are not in JSON format");
                        }
                    }
                })

                pricesDropdownMenu.appendChild(li)
            })

            // Remove Item from Card
            cartItem
                .querySelector(".remove-item")
                .addEventListener("click", (el) => {
                    document
                        .querySelector(`.point-${seat.id}`)
                        .classList.remove("seat--selected");

                    // Remove Cart Item in HTML
                    // el.target.parentElement.parentElement.parentElement.remove();
                    document
                        .querySelector(`.booked-seat-${seat.id}`).remove()


                    // Remove Cart Item in Object
                    delete cart[seat.id];

                    if (Object.keys(cart).length === 0) {
                        emptySeats.style.display = "flex";
                        proceedButton.classList.add('disabled')
                    }

                    // Remove from localStorage
                    try {
                        const reservations = JSON.parse(localSavedReservations());
                        delete reservations.reservations[seat.id];

                        if(Object.keys(reservations).length !== 0){
                            localStorage.setItem(`map-reservations-${mainElement.dataset.event_id}`, JSON.stringify(reservations))
                        } else {
                            // removeFromLocalStorage()
                        }
                    } catch (err) {
                        removeFromLocalStorage()
                        console.error("Saved Reservation on localStorage are not in JSON format");
                    }
                });

            cartEl.appendChild(cartItem);
        };

        // Fetch Reserved Seats
        const fetchReserved = () => {
            return
        }

        termsCheckbox.addEventListener("change", (el) => {
            if (localSavedReservations()){
                try {
                    const reservations = JSON.parse(localSavedReservations())
                    reservations.terms = el.target.checked;
                    localStorage.setItem(`map-reservations-${mainElement.dataset.event_id}`, JSON.stringify(reservations))
                } catch (err) {
                    removeFromLocalStorage()
                    console.error("Saved Reservation on localStorage are not in JSON format");
                }
            }
        })

        const renderSeats = async () => {
            // Fetch Seats Map
            // const res = await fetch("./data.json");
            // const data = await res.json();
            const data = seatsData;
            const types = seatsTypes;

            $.ajax({
                url: url_reserved,
                type: 'GET',
                dataType: 'JSON',
                error: function () {
                    alert(trans.failed_loading_reserved);
                },
                success: function (response) {
                    // Selected Seats
                    const selectedSeats = Object.assign(...response.map((key) => ({[key]: 1})));

                    // Remove UnAvailable Reservations seats from localStorage
                    if (localSavedReservations()){
                        try {
                            const reservations = JSON.parse(localSavedReservations());

                            for (const key in reservations.reservations) {
                                if (selectedSeats[key]){
                                    delete reservations.reservations[key]
                                } else {
                                    selectedSeats[key] = 1;
                                }
                            }

                            localStorage.setItem(`map-reservations-${mainElement.dataset.event_id}`, JSON.stringify(reservations))
                        } catch (err) {
                            removeFromLocalStorage()
                            console.error("Saved Reservation on localStorage are not in JSON format")
                        }
                    }

                    const bounds = {
                        data: data.features.map((item)=>{
                            if (item.type) {
                                return item.geometry.coordinates
                            }
                        })
                    };

                    map.fitBounds(bounds.data);
                    map.setZoom(map.getBoundsZoom(bounds.data));

                    delete bounds.data

                    // Create geoJson Points
                    L.geoJson(data, {
                        pointToLayer: function (feature, latlng) {
                            const {category, color, id} = feature.properties;

                            // Marker config
                            const geojsonMarkerOptions = {
                                radius: 0.8,
                                fillColor: color,
                                weight: 0,
                                fillOpacity: 1,
                                color: color,
                                opacity: 1,
                                className: `point-${id} ${
                                    // ToDo: Distinguish if seat is taken or unavailable
                                    (selectedSeats[id] || types[category] == undefined) ? "seat--selected" : ""
                                }`,
                            };

                            const marker = L.circleMarker(latlng, geojsonMarkerOptions);

                            // Create Marker
                            return marker;
                        },
                        style: function (geoJsonFeature) {
                            if(geoJsonFeature.geometry.type == "Polygon") {
                                return {
                                    'color' : '#727272',
                                    'fill' : '#727272',
                                    'fillOpacity' : 1,
                                };
                            }

                            return {};
                        }
                    })
                        .bindPopup(function (layer) {
                            if(layer.feature.geometry.type == 'Polygon') {
                                if(layer.feature.properties.type == "Stage") {
                                    return trans.stage;
                                }
                            } else if(layer.feature.geometry.type == 'Point') {
                                return trans.loading;
                            }
                        })
                        .on("click", async function (point) {
                            if(point.layer.feature.geometry.type != "Point") {
                                return;
                            }

                            const popup = point.target.getPopup();
                            popup.setContent(trans.loading);
                            popup.update();

                            // Point Data
                            const { color, id } = point.layer.feature.properties;

                            // Check id seat is taken
                            if (
                                cart[id] ||
                                document
                                    .querySelector(`.point-${id}`)
                                    .classList.contains("seat--selected")
                            ) {
                                popup.setContent("<div>" + trans.taken_seat + "</div>");
                                popup.update();
                                return;
                            }

                            const { success, data } = await getSeat(point.layer.feature.properties);

                            // Check id seat is taken
                            if (!success) {
                                popup.setContent("<div>" + trans.taken_seat + "</div>");
                                popup.update();
                                document
                                    .querySelector(`.point-${id}`)
                                    .classList.add("seat--selected");

                                return;
                            }

                            const wrapper = document.createElement("div");
                            const popupEl = document.importNode(
                                document.querySelector("#popup-template").content,
                                true
                            );

                            popupEl.querySelector(
                                ".popup-blob div"
                            ).style.backgroundColor = color;

                            popupEl.querySelector(".seat-name").textContent = data.title;

                            popupEl.querySelector(".seat-info span.block").textContent = data.block;
                            popupEl.querySelector(".seat-info span.rows ").textContent = data.row;
                            popupEl.querySelector(".seat-info span.place").textContent = data.seat;

                            var tickets_tooltip = [];

                            for (const [i, e] of Object.entries(data.prices)) {
                                tickets_tooltip.push(e)
                            }
                            // data.prices.forEach((e, i) => {
                            //     tickets_tooltip.push(e)
                            // })

                            for(var i = 0; i < tickets_tooltip.length; i++){
                                if(tickets_tooltip[i].type == 'full'){
                                    var type_full = true;
                                    var type_full_key = i;
                                }else if(tickets_tooltip[i].type == 'reduced'){
                                    var type_reduced = true;
                                    var type_reduced_key = i;
                                }else if(tickets_tooltip[i].type == 'student'){
                                    var type_student = true;
                                    var type_student_key = i;
                                }else if(tickets_tooltip[i].type == 'free'){
                                    var type_free = true;
                                    var type_free_key = i;
                                }
                            }

                            var tickets_tooltip_show = [];
                            if( type_full ){
                                tickets_tooltip_show.push(tickets_tooltip[type_full_key])
                            }

                            if( type_reduced ){
                                tickets_tooltip_show.push(tickets_tooltip[type_reduced_key])
                            }

                            if( type_student ){
                                tickets_tooltip_show.push(tickets_tooltip[type_student_key])
                            }

                            if( type_free ){
                                tickets_tooltip_show.push(tickets_tooltip[type_free_key])
                            }

                            tickets_tooltip_show.forEach((e) => {
                                const price_element = document.createElement('strong');
                                price_element.classList.add('seat-price');
                                price_element.innerHTML = `<span class="price__type">${trans[e.type]}</span> - €<span class="price__ammount">${e.price || 0}</span>`;
                                if (popupEl.querySelector(".popup-info .seat-prices")) {
                                    popupEl.querySelector(".popup-info .seat-prices").appendChild(price_element);
                                }
                            })

                            // Add Event Listener
                            if (popupEl.querySelector(".add-to-cart-button")) {
                                popupEl
                                    .querySelector(".add-to-cart-button")
                                    .addEventListener("click", (e) => {
                                        if (currentPoint.classList.contains("seat--selected")) {
                                            return;
                                        }

                                        var tickets_tooltip = [];
                                        for (const [i, e] of Object.entries(data.prices)) {
                                            tickets_tooltip.push(e)
                                        }
                                        // data.prices.forEach((e, i) => {
                                        //     tickets_tooltip.push(e)
                                        // })

                                        for (var i = 0; i < tickets_tooltip.length; i++) {
                                            if (tickets_tooltip[i].type == 'full') {
                                                var type_full = true;
                                                var type_full_key = i;
                                            } else if (tickets_tooltip[i].type == 'reduced') {
                                                var type_reduced = true;
                                                var type_reduced_key = i;
                                            } else if (tickets_tooltip[i].type == 'student') {
                                                var type_student = true;
                                                var type_student_key = i;
                                            } else if (tickets_tooltip[i].type == 'free') {
                                                var type_free = true;
                                                var type_free_key = i;
                                            }
                                        }

                                        var tickets_tooltip_show = [];
                                        if (type_full) {
                                            tickets_tooltip_show.push(tickets_tooltip[type_full_key])
                                        }

                                        if (type_reduced) {
                                            tickets_tooltip_show.push(tickets_tooltip[type_reduced_key])
                                        }

                                        if (type_student) {
                                            tickets_tooltip_show.push(tickets_tooltip[type_student_key])
                                        }

                                        if (type_free) {
                                            tickets_tooltip_show.push(tickets_tooltip[type_free_key])
                                        }

                                        data.prices = [];
                                        tickets_tooltip_show.forEach((item) => {
                                            data.prices.push(item);
                                        })

                                        data.prices.forEach((item) => {
                                            delete item.event;
                                        })

                                        addToCart({...data, priceId: data.prices[0].id, color});

                                        localStorage.setItem(`map-reservations-${mainElement.dataset.event_id}`, JSON.stringify({
                                            terms: termsCheckbox.checked,
                                            reservations: cart
                                        }))

                                        currentPoint.classList.add("seat--selected");
                                        map.closePopup();
                                    });
                            }

                            wrapper.appendChild(popupEl);

                            popup.setContent(wrapper);
                            popup.update();
                        })
                        .addTo(map);

                    // Grab all markers (seats)
                    document.querySelectorAll("path.leaflet-interactive").forEach((e) => {
                        e.addEventListener("click", (el) => {
                            // Check if Seat is taken
                            if (el.style && el.style.fill === "black") {
                                return;
                            }

                            // Add seat
                            currentPoint = el.target;
                        });
                    });
                },
            });


        };

        renderSeats();

        if (localSavedReservations()) {
            try {
                const reservations = JSON.parse(localSavedReservations());
                termsCheckbox.checked = reservations.terms;

                for (const key in reservations.reservations) {
                    addToCart(reservations.reservations[key])
                }
            } catch (err) {
                removeFromLocalStorage()
                console.error("Saved Reservation on localStorage are not in JSON format")
            }
        }


        // Save seats to database
        const reserveSeatsRequest = async () => {
            // Remove all Error Messages
            document.querySelectorAll(`span.error[for]`).forEach((item)=>{
                item.style.display = "none"
            })

            const stripe = Stripe(process.env.MIX_STRIPE_KEY);
            const url = mainElement.dataset.url;
            const data = [];

            // Clean up data for requested format
            for (const itemKey in cart) {
                data.push({
                    ticket_type_id: cart[itemKey].priceId,
                    seat: itemKey
                })
            }

            const options = {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
                body: JSON.stringify({
                    seats: data,
                    payment_method: 'stripe',
                    accept_terms: termsCheckbox.checked ? 1 : 0
                }),
            };

            const reservationLoaderClassName = "reservation__loader";

            appendLoader(reservationLoaderClassName);

            try {
                const request = await fetch(url, options);
                const response_json = await request.json();

                console.log(request.status);
                console.log(response_json);

                if([401, 402, 403].indexOf(request.status) != -1){
                    toastr.error(trans.unauthenticated);
                } else if(request.status === 422){
                    $.each(response_json, function (key, message){
                        if(message.length === 1){
                            if($('span.error[for="' + key + '"]').length !== 0) {
                                $('span.error[for="' + key + '"]').html(message).show();
                            } else {
                                $('span.error[for="' + key.replace(/(?:\.)(.*?)(?=\.)/, '.*') + '"]').html(message).show();
                            }
                        } else {
                            if($('span.error[for="' + key + '"]').length !== 0) {
                                $('span.error[for="' + key + '"]').html(message.join("\n")).show();
                            } else {
                                $('span.error[for="' + key.replace(/(?:\.)(.*?)(?=\.)/, '.*') + '"]').html(message.join("\n")).show();
                            }
                        }
                    });
                } else if(request.status >= 200 && request.status < 300){
                    removeFromLocalStorage();

                    if(response_json.message) {
                        toastr.success(response_json.message)
                    } else if(response_json.id) {

                        return stripe.redirectToCheckout({ sessionId: response_json.id });
                    }

                    if(response_json.redirect){
                        setTimeout(function () {
                            window.location.href = response_json.redirect;
                        }, 3000);
                    }
                } else {
                    if(response_json.message == 'Unauthenticated.') {
                        toastr.error(trans.unauthenticated);
                    } else {
                        toastr.error(response_json.message);
                    }
                }
            } catch (err){
                console.error("Something Went Wrong: ", err)
            }

            removeLoader(reservationLoaderClassName);
        };

        let submitLoading = false;
        proceedButton.addEventListener("click",async ()=>{
            let emptyOrders = Object.keys(cart).length === 0;

            if (emptyOrders || submitLoading) return;

            submitLoading = true;
            await reserveSeatsRequest();
            submitLoading = false;
        })

        // Grab all markers (seats)
        document.querySelectorAll("path").forEach((e) => {
            e.addEventListener("click", (el) => {
                // Check if Seat is taken
                if (el.style && el.style.fill === "black") {
                    return;
                }

                // Add seat
                currentPoint = el.target;
            });
        });

        // Check if the clicked el is not a marker
        window.addEventListener("click", (el) => {
            if (el.target !== currentPoint) {
                if (currentPoint && currentPoint.style.fill === "black") {
                    console.log("place selected");
                }
            }
        });

        window.addEventListener( "pageshow", function ( event ) {
            var historyTraversal = event.persisted ||
                ( typeof window.performance != "undefined" &&
                    window.performance.navigation.type === 2 );
            if ( historyTraversal ) {
                // Handle page restore.
                window.location.reload();
            }
        });

        //Manage Seats Size
        map.on("zoomend", function () {
            document.querySelectorAll(".leaflet-interactive").forEach((path) => {
                path.style.strokeWidth = `${map.getZoom() * (map.getZoom() / 1.8)}px`;
            });
        });
    })
}
