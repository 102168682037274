const scroll_to_anchor_class = '.__scroll_to_anchor';

$(document).ready(function () {
    //get the full url
    let url = window.location.href;

    if (url.includes("#")) {
        //split the url by # and get the anchor target name
        let parts = url.split("#");
        let target = parts[1];

        scrollToAnchor(`#${target}`);
    }

    $(`body`).on('click', scroll_to_anchor_class, function (e) {
        e.preventDefault();

        let el = e.target;

        let href = el.getAttribute("href");

        // if element has hyperlink scroll to that
        if (href !== undefined && href !== '') {
            return scrollToAnchor(href);
        }

        // else find a data-scroll-to-anchor attribute on the element
        let target = el.dataset.scrollToAnchor;

        return scrollToAnchor(target);
    });
});

function scrollToAnchor(target, offset = 200) {
    //get the top offset of the target anchor
    let target_offset = $(`${target}`).offset();

    if (target_offset !== undefined) {
        let target_top = target_offset.top - offset;

        //goto that anchor by setting the body scroll top to anchor top
        $('html, body').animate({scrollTop: target_top}, 1000);
    }
}
