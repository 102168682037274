$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

String.prototype.withCurrency = function (currency) {

    return `${currency} ${this}`;
}
