
// $(function() {
    $("#dzUpload").dropzone({
        url: '/media/upload',
        acceptedFiles: ".jpeg, .jpg, .png, .gif, .pdf, .mp4",
        addRemoveLinks: true,
        timeout: 5000,
        success: function(file, response)
        {
            console.log(response);
        },
        error: function(file, response)
        {
            return false;
        }
    });
// });
