import { loadStripe } from "@stripe/stripe-js";

$(document).ready(function () {
    $('#credit-card').trigger('click');

    const eventBookingMap = $('.reservation__option');

    // Check if we are at page /it/evento/xxx/prenota
    if (eventBookingMap.length) {
        localStorage.clear();
        // Selected seats container element
        const eventBookingSeats = document.querySelector('.events-booking-seats');
        // Available Reservations container element
        const selectedReservations = document.querySelector('#select_reservation');
        // Available Subscription container element
        const selectedSubscriptions = document.querySelectorAll('.select_reservation_subscription');
        // List of Reservations items
        let reservationsOptions = selectedReservations.querySelectorAll('.reservation__option');
        // List of Subscription items
        selectedSubscriptions.forEach((selectedReservation, index) => {
            // Join each cycle subscription on reservation options
            let subscriptionOptions = selectedReservation.querySelectorAll('.reservation__option');
            reservationsOptions = Array.prototype.concat.call(...reservationsOptions , ...subscriptionOptions);
        });
        // Empty Booking Seats Element
        const emptyBookingSeats = eventBookingSeats.querySelector('.events-booking-seats-empty');
        // Payment Method Element
        const paymentMethod = document.querySelector("#payment_method__items")
        // Proceed Button Element
        const proceedButton = document.querySelector('.events-booking-seats-proceed button');
        // Saved Reservations on localStorage
        const localSavedReservations = localStorage.getItem(`manual-reservations-${selectedReservations.dataset.id}`);
        // Terms and Conditions Checkbox Element
        const termsCheckbox = document.querySelector("#terms")

        // Conversion of List of Available Reservations items to Array
        /* Example
          *[
              {
                  "title": "Reservation 1",
                  "availableReservations": [
                      {
                          "type": "Intero",
                          "price": 50,
                          "quantity": 2,
                          "available": true,
                          "loading": false
                      },
                      {
                          "type": "Ridotto",
                          "price": 25,
                          "quantity": 0,
                          "available": true,
                          "loading": false
                      }
                  ]
              },
              {
                  "title": "Reservation 2",
                  "availableReservations": [
                      {
                          "type": "Intero",
                          "price": 30,
                          "quantity": 0,
                          "available": false,
                          "loading": false
                      }
                  ]
              }
          ]
          *
          * */
        let bookingReservations = [];

        /**
         * Create Html component for a single reservation order
         *
         * @param {string} title
         * @param {number} price
         * @param {string} type
         * @param {number} optionIndex
         * @param {number} typeIndex
         *
         * @return HTMLDivElement
         */
        const createSingleSeat = (title, price, type, optionIndex, typeIndex) => {
            // Copy Single Order HTML Template
            const singleCartItemTemplate = document.importNode(
                document.querySelector('#manual-cart-item-template').content,
                true
            );

            singleCartItemTemplate.querySelector('.item-title').textContent = title; // Set title for single order
            singleCartItemTemplate.querySelector('.manual-card-item-desc').textContent = type; // Set type for single order
            singleCartItemTemplate.querySelector('.manual-card-item-price span').textContent = price; // Set price for single order

            const removeOrderElement = singleCartItemTemplate.querySelector('.remove-item');

            // Remove Order
            removeOrderElement.addEventListener('click', () => {

                // Update price of input on list of Reservations items
                reservationsOptions[optionIndex]
                    .querySelectorAll('ul.post_locations li.border-bottom.py-3.my-1')
                    .forEach((item, index) => {
                        if (typeIndex === index) {
                            item.querySelector('.button_quantity span').textContent = 0;
                            changeMaxNrOfSeats(reservationsOptions, item, 0)
                        }
                    });

                // Set Order quantity to 0
                bookingReservations[optionIndex].availableReservations[typeIndex].quantity = 0;

                // Recreate Orders list
                createSelectedSeats();

                // Active disable mask
                activeDisableMask();
            });

            return singleCartItemTemplate;
        };

        // Create Order list elements
        const createSelectedSeats = () => {
            let emptyOrders = true;

            // Check if there are any orders
            for (const reservation of bookingReservations) {
                for (const available of reservation.availableReservations) {
                    if (available.quantity) {
                        emptyOrders = false;
                    }
                }
            }

            // Update Reservations saved to localStorage
            localStorage.setItem(
                `manual-reservations-${selectedReservations.dataset.id}`,
                JSON.stringify({
                    terms: termsCheckbox.checked,
                    bookingReservations
                })
            );

            // Change style based on available orders
            emptyBookingSeats.style.display = emptyOrders ? 'flex' : 'none';
            proceedButton.classList[emptyOrders ? 'add' : 'remove']('disabled');

            // Main Orders container element
            const bookingSeatsItemsElement = eventBookingSeats.querySelector('ul.events-booking-seats-items');
            // Reset content to nothing
            bookingSeatsItemsElement.innerHTML = '';

            let paymentMethodVisibility = false;

            // Loop through bookingReservations and create Order Card element
            for (const [optionIndex, reservation] of bookingReservations.entries()) {
                const { title, availableReservations } = reservation;

                for (const [typeIndex, selectedReservations] of availableReservations.entries()) {
                    const { price, quantity, type } = selectedReservations;

                    if (quantity > 0  && price > 0 &&  !paymentMethodVisibility){
                        paymentMethodVisibility = true;
                    }

                    if (selectedReservations.quantity) {
                        const item = createSingleSeat(title, (price * quantity).toFixed(2), type, optionIndex, typeIndex);
                        bookingSeatsItemsElement.appendChild(item);
                    }
                }
            }

            paymentMethod.style.display = paymentMethodVisibility ? "block" : "none"
        };

        const activeDisableMask = () => {
            let singleTicket = document.querySelector('#select_reservation');
            let subscriptionTicket = document.querySelector('#select_reservation_subscription');

            let singleTicketTotalPrice = 0;
            let subscriptionTicketTotalPrice = 0;

            singleTicket.querySelectorAll('ul.post_locations li.border-bottom.py-3.my-1').forEach(item => {
                singleTicketTotalPrice += parseInt(item.querySelector('.button_quantity span').innerHTML);
            })

            subscriptionTicket.querySelectorAll('ul.post_locations li.border-bottom.py-3.my-1').forEach(item => {
                subscriptionTicketTotalPrice += parseInt(item.querySelector('.button_quantity span').innerHTML)
            })

            if ( singleTicketTotalPrice > 0 ) {
                document.querySelector('#disable-mask-subscription').classList.add('disable-mask-active');
                document.querySelector('#disable-mask-ticket').classList.remove('disable-mask-active');
            } else if ( subscriptionTicketTotalPrice > 0) {
                document.querySelector('#disable-mask-ticket').classList.add('disable-mask-active');
                document.querySelector('#disable-mask-subscription').classList.remove('disable-mask-active');
            } else {
                document.querySelector('#disable-mask-ticket').classList.remove('disable-mask-active');
                document.querySelector('#disable-mask-subscription').classList.remove('disable-mask-active');
            }
        }

        // Loop through reservationsOptions Element and create bookingReservations array
        const createBookingReservations = () => {
            let parsedReservations = null;

            if (localSavedReservations) {
                try {
                    const reservations = JSON.parse(localSavedReservations);
                    parsedReservations = reservations.bookingReservations;
                    termsCheckbox.checked = reservations.terms;
                } catch (err) {
                    removeFromLocalStorage()
                    console.error("Saved Reservation on localStorage are not in JSON format")
                }
            }

            reservationsOptions.forEach((option, optionIndex) => {
                const title = option.querySelector('.col-lg-4 p.font-weight-bold').textContent;
                let availableReservations = [];

                option.querySelectorAll('ul.post_locations > li').forEach((item, index) => {
                    const priceElement = item.querySelector('.details .reservation__option__price');
                    const quantityElement = item.querySelector('.button_quantity span');

                    let quantity = 0;
                    if (quantityElement) {
                        quantity = parseInt(quantityElement.textContent, 10);
                    }

                    if (parsedReservations) {
                        const savedQuantity = parsedReservations[optionIndex].availableReservations[index].quantity;

                        if (savedQuantity > 0){
                            quantity = savedQuantity;
                            quantityElement.textContent = savedQuantity;
                        }
                    }

                    availableReservations.push({
                        id: item.dataset.id,
                        url: item.dataset.url,
                        type: item.querySelector('.reservation__option__type').textContent.trim(),
                        price: parseFloat(priceElement.textContent.replace('€ ', '').replace(',', '.')),
                        quantity,
                        available: true,
                        loading: false,
                        ticket_type: item.dataset.type
                    });

                    const orderQuantityInput = item.querySelector('.button_quantity');

                    item.querySelectorAll('.button_quantity button').forEach((button) => {
                        const action = button.getAttribute('data-inc');

                        // When user Changes a single Order quantity recreate Orders list Html
                        button.addEventListener('click', async (el) => {
                            const reservationQuantity = bookingReservations[optionIndex].availableReservations[index];
                            const max = orderQuantityInput.dataset.max;

                            if(action === '+1' && (reservationQuantity.quantity +1) > parseInt(max, 10)){
                                return;
                            }

                            if (!reservationQuantity.available) return;

                            if (reservationQuantity.loading) return;

                            const checkAvailableSeatsCount = async () => {
                                return true;
                            };

                            reservationQuantity.loading = true;
                            const areAvailableSeats = await checkAvailableSeatsCount();
                            reservationQuantity.loading = false;

                            if (!areAvailableSeats) {
                                orderQuantityInput.classList.add('disabled');
                                reservationQuantity.available = false;
                                reservationQuantity.quantity = 0;

                                try {
                                    toastr.error(JSON.parse(selectedReservations.dataset.trans).noAvailableErrorMessage);
                                } catch (e) {
                                    console.error("Error message is not in JSON format")
                                }

                                // Recreate Orders list Html and remove unavailable seats
                                createSelectedSeats();
                                return;
                            }

                            if (action === '+1') {
                                reservationQuantity.quantity += 1;
                                changeMaxNrOfSeats(reservationsOptions, item, -1);
                            }
                            if (action === '-1' && reservationQuantity.quantity > 0) {
                                reservationQuantity.quantity -= 1;
                                changeMaxNrOfSeats(reservationsOptions, item, 1);
                            }

                            if (action === '-1' && reservationQuantity.quantity === 0) {
                                // document.querySelector('#disable-mask-ticket').classList.remove('disable-mask-active');
                            }

                            quantityElement.textContent = reservationQuantity.quantity;

                            // Recreate Orders list Html
                            createSelectedSeats();

                            // Active disable mask
                            activeDisableMask();
                        });
                    });
                });

                bookingReservations.push({
                    title,
                    availableReservations,
                });
            });
        };

        const removeFromLocalStorage = () => {
            localStorage.removeItem(`manual-reservations-${selectedReservations.dataset.id}`)
        }

        // Save seats to database
        const reserveSeatsRequest = async () => {
            // Remove all Error Messages
            document.querySelectorAll(`span.error[for]`).forEach((item)=>{
                item.style.display = "none"
            })

            const stripe = Stripe(process.env.MIX_STRIPE_KEY);
            const ticket_url = selectedReservations.dataset.url;
            const ticket_data = [];
            let subscription_url;
            let subscription_data = [];
            let subscription_options;

            // Clean up data for requested format
            for (const item of bookingReservations) {
                for (const reservation of item.availableReservations) {
                    if (reservation.quantity > 0 && reservation.ticket_type !== 'subscription'){
                        ticket_data.push({
                            'ticket_type_id': reservation.id,
                            'quantity': reservation.quantity
                        })
                    } else if (reservation.quantity > 0 && reservation.ticket_type === 'subscription') {
                        subscription_url = reservation.url;
                        subscription_data.push({
                            'cycle_id': reservation.id,
                            'ticket_type': reservation.type,
                            'quantity': reservation.quantity
                        });
                    }
                }
            }

            if (selectedSubscriptions.length) {
                subscription_options = {
                    method: 'POST',
                    credentials: "same-origin",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                    },
                    body: JSON.stringify({
                        subscriptions: subscription_data,
                        payment_method: 'stripe',
                        accept_terms: termsCheckbox.checked ? 1 : 0,
                        event_id: selectedSubscriptions[0].dataset.event
                    }),
                };
            }

            const ticket_options = {
                method: 'POST',
                credentials: "same-origin",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                },
                body: JSON.stringify({
                    seats: ticket_data,
                    payment_method: 'stripe',
                    accept_terms: termsCheckbox.checked ? 1 : 0
                }),
            };

            try {

                if (ticket_data.length && subscription_data.length) {
                    throw 'Something Went Wrong';
                } else if (ticket_data.length && !subscription_data.length) {
                    await sendRequest(ticket_url, ticket_options, removeFromLocalStorage(), stripe, selectedReservations);
                } else if (subscription_data.length && !ticket_data.length) {
                    await sendRequest(subscription_url, subscription_options, removeFromLocalStorage(), stripe, selectedSubscriptions[0]);
                }

            } catch (err){
                console.error("Something Went Wrong")
            }
        };

        let submitLoading = false;
        proceedButton.addEventListener('click', async () => {
            let emptyOrders = true;

            // Check if there are any orders
            for (const reservation of bookingReservations) {
                for (const available of reservation.availableReservations) {
                    if (available.quantity) {
                        emptyOrders = false;
                    }
                }
            }

            if (emptyOrders || submitLoading) return;

            submitLoading = true;
            await reserveSeatsRequest();
            submitLoading = false;
        });

        termsCheckbox.addEventListener("change", (el) => {
            if (localSavedReservations){
                try {
                    const reservations = JSON.parse(localSavedReservations)
                    reservations.terms = el.target.checked;
                    localStorage.setItem(`manual-reservations-${selectedReservations.dataset.id}`, JSON.stringify(reservations))
                } catch (err) {
                    console.error("Saved Reservation on localStorage are not in JSON format")
                }
            }
        })

        createBookingReservations();

        if (localSavedReservations) {
            try {
                const parsedReservations = JSON.parse(localSavedReservations).bookingReservations;
                const savedReservations = {};

                for (const item of parsedReservations) {
                    for (const reservation of item.availableReservations) {
                        savedReservations[reservation.id] = reservation.quantity;
                    }
                }

                for (const [itemIndex, item] of bookingReservations.entries()) {
                    for (const [reservationIndex, reservation] of item.availableReservations.entries()) {
                        if (savedReservations[reservation.id]){
                            bookingReservations[itemIndex].availableReservations[reservationIndex].quantity = savedReservations[reservation.id]
                        }
                    }
                }
            } catch (err) {
                removeFromLocalStorage()
                console.error("Saved Reservation on localStorage are not in JSON format")
            }

            createSelectedSeats();
        }
    }
});

async function sendRequest(url, options, remove, stripe, selectedReservations) {
    const request = await fetch(url, options);
    const response_json = await request.json();

    if([401, 402, 403].indexOf(request.status) !== -1){
        toastr.error(JSON.parse(selectedReservations.dataset.trans).unauthenticated);
    } else if(request.status === 422){
        $.each(response_json, function (key, message){

            if(key.indexOf('seats.') != -1){
                let current_ticket_type_id = JSON.parse(options.body).seats[parseInt(key.split('.')[1])].ticket_type_id;

                key = `seats.${current_ticket_type_id}`;
            }

            if(message.length === 1){
                $('span.error[for="' + key + '"]').html(message).show();
            } else {
                $('span.error[for="' + key + '"]').html(message.join("\n")).show();
            }
        });
    } else if(request.status >= 200 && request.status < 300){
        remove;

        if(response_json.message) {
            toastr.success(response_json.message)
        } else if(response_json.id) {

            return stripe.redirectToCheckout({ sessionId: response_json.id });
        }

        if(response_json.redirect){
            setTimeout(function () {
                window.location.href = response_json.redirect;
            }, 3000);
        }
    } else {
        if(response_json.message === 'Unauthenticated.') {
            toastr.error(JSON.parse(selectedReservations.dataset.trans).unauthenticated);
        } else {
            toastr.error(response_json.message);
        }
    }
}

const changeMaxNrOfSeats = (reservationOptions, ticket, value) =>
{
    reservationOptions.forEach(options => {

        let option = options.querySelectorAll('ul.post_locations > li');

        option.forEach(item => {

            if(item.dataset.type === 'subscription'){

                if(item !== ticket){

                    let element = item.querySelector('.reservation__option__content > .details > .quantity > .button_quantity');
                    let max = parseInt(element.dataset.max);
                    max += value;
                    element.dataset.max = max;
                }
            } else {

                if(item !== ticket && item.dataset.group === ticket.dataset.group){

                    let total = total_seats(reservationOptions, ticket);
                    let total_bought = total_bought_seats(reservationOptions, ticket);
                    let element = item.querySelector('.reservation__option__content > .details > .quantity > .button_quantity');
                    let total_bought_ticket_of_element = parseInt(element.querySelector('span').innerHTML);

                    // if we click '+' the value of total bought tickets increases with 1
                    // if we click '-' the value of total bought tickets decreases with 1
                    total_bought -= value;

                    if ( total - total_bought + total_bought_ticket_of_element <= parseInt(element.dataset.absolute_max) ) {
                        element.dataset.max = total - total_bought + total_bought_ticket_of_element;
                    } else if ( total - total_bought + total_bought_ticket_of_element > parseInt(element.dataset.absolute_max) ) {
                        element.dataset.max = element.dataset.absolute_max;
                    }
                }
            }
        })
    })
}

const total_seats = (reservationOptions, ticket) =>
{
    let total = 0;
    reservationOptions.forEach(options => {
        let option = options.querySelectorAll('ul.post_locations > li');
        option.forEach(item => {
            if(item.dataset.type !== 'subscription'){
                if(item.dataset.group === ticket.dataset.group){
                    let element = item.querySelector('.reservation__option__content > .details > .quantity > .button_quantity');
                    if (total < parseInt(element.dataset.absolute_max)) {
                        total = parseInt(element.dataset.absolute_max);
                    }
                }
            }
        })
    })
    return total;
}

const total_bought_seats = (reservationOptions, ticket) =>
{
    let total = 0;
    reservationOptions.forEach(options => {
        let option = options.querySelectorAll('ul.post_locations > li');
        option.forEach(item => {
            if(item.dataset.type !== 'subscription'){
                if(item.dataset.group === ticket.dataset.group){
                    let element = item.querySelector('.reservation__option__content > .details > .quantity > .button_quantity > span');
                    total += parseInt(element.innerHTML);
                }
            }
        })
    })
    return total;
}
