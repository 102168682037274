$(document).ready(function (){

    const reCAPTCHA_site_key = $('meta[name="reCAPTCHA_site_key"]').attr('content');

    $('.simple-subscribe-form').submit(function (e) {
        e.preventDefault();
        const form = $(this);

        grecaptcha.ready(function() {
            grecaptcha.execute(reCAPTCHA_site_key, {action: 'submit'}).then(function(token) {
                form.find('input[name=reCAPTCHA_token]').val(token);
                submitSubscribeForm(form, true);
            });
        });
    });


    $('.lead-form-ajax').submit(function (e) {
        e.preventDefault();
        const form = $(this);

        grecaptcha.ready(function() {
            grecaptcha.execute(reCAPTCHA_site_key, {action: 'submit'}).then(function(token) {
                form.find('input[name=reCAPTCHA_token]').val(token);
                submitSubscribeForm(form);
            });
        });
    });

    function submitSubscribeForm($form, showAllErrorsOnToastr = false)
    {
        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: $form.serialize(),
            dataType: 'JSON',
            cache: false,
            beforeSend: function() {
                $form.find('button[type="submit"]').attr('disabled', true);
                $form.find('button[type="submit"]').prepend('<i class="fas fa-spinner fa-spin"></i> ');
                $form.find('div.invalid-feedback').remove();
                $form.find('.is-invalid').removeClass('is-invalid');
            },
            success: function(response){
                $form.find(':input:not([readonly])')
                    .not(':button, :submit, :reset, :hidden')
                    .val('')
                    .prop('checked', false)
                    .prop('selected', false);

                if (response.type === 'error') {
                    toastr.error(response.message, response.title, response.options);
                } else if (response.type === 'success') {
                    toastr.success(response.message, response.title, response.options);
                }
                $('#toast-container.toast-bottom-full-width > div').css("max-width", "100vw");
            },
            error: function(response){
                if (response.status === 422) {
                    if (showAllErrorsOnToastr) {
                        let errors = response.responseJSON.message;
                        $.each(response.responseJSON.errors, function(inputName, errorMessage){
                            errors += '<br />- ' + errorMessage[0];
                        });
                        toastr.options.positionClass = "toast-bottom-full-width";
                        toastr.error(errors);
                    } else {
                        toastr.options.positionClass = "toast-bottom-full-width";
                        toastr.error(response.responseJSON.message);
                        $.each(response.responseJSON.errors, function(inputName, errorMessage){
                            let $input = $form.find('[name='+inputName+']');
                            $input.addClass('is-invalid');
                            $input.parent().append('<div class="invalid-feedback">'+errorMessage[0]+'</div>');
                        });
                    }
                    $('#toast-container.toast-bottom-full-width > div').css("max-width", "100vw");
                }
            },
            complete: function () {
                $form.find('button[type="submit"]').attr('disabled', false);
                $form.find('button[type="submit"] i.fa-spin').remove();
            }
        });
    }
});
