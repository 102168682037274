import { loadStripe } from "@stripe/stripe-js";
import { appendLoader, removeLoader } from "../utils/loader"

const {fetchAvailableHours, fetchHoursPrice, createDropdownItems, parseTime} = require("./utils")

const form = $("#reserveForm"),
    submitButton = document.getElementById("reservation_submit_btn");

$(document).ready(function () {
    const mainElement = document.querySelector(".reservation-wrapper");

    if (!mainElement){
        return;
    }

    const hall_id = document.querySelector("input[name=hall_id]").value;

    const getHallLocalStorage = () => {
        if (window.localStorage.hasOwnProperty('halls')) {
            let halls = JSON.parse(window.localStorage.halls);

            if (!halls.hasOwnProperty(hall_id)) {
                halls[hall_id] = {};
            }

            return halls[hall_id];
        } else {
            window.localStorage.halls = "{}";

            return getHallLocalStorage();
        }
    }

    /**
     * write or overwrite to the stored hall
     * if key is an object and value is null
     * the hall is overwritten by the object
     *
     * @param key
     * @param val
     */
    const updateHallLocalStorage = (key, val = null) => {
        let all_halls = JSON.parse(window.localStorage.halls);
        let hall = getHallLocalStorage()

        if(typeof key === 'object' && value === null) {
            hall = key;
        } else {
            hall[key] = val;
        }

        all_halls[hall_id] = hall;

        window.localStorage.halls = JSON.stringify(all_halls);
    }

    if (!mainElement) {
        return;
    }

    const state = {
        availableHours: [],
        reservations: [],
        addInputsSwitch: false,
        selectedDate: null,
        inputCount: 0
    }


    const reservationWrapper = document.querySelector(".reservation-wrapper");
    const loader = reservationWrapper.querySelector(".loader")
    const generalError = reservationWrapper.querySelector('.reservation-general-error');
    const noSlotsError = reservationWrapper.querySelector('.reservation-slots-error');

    async function init(){
        clearSummary(); // clears reservations when date is changed
        clearErrors();
        loader.classList.remove("loader-hidden");
        state.addInputsSwitch = false;
        addReservationInputsButton.style.display = "none"
        reservationInputsWrapper.innerHTML = "";

        const date = inputDate.value;
        state.selectedDate = date;

        const {success, hours} = await fetchAvailableHours(date);

        if(hours.length === 0){
            loader.classList.add("loader-hidden");
            noSlotsError.style.display = "block";
            return;
        }


        if (!success) {
            loader.classList.add("loader-hidden");
            generalError.style.display = "block";
            return;
        }

        state.availableHours = hours

        const inputs = createReservationInputs();
        reservationInputsWrapper.appendChild(inputs);

        let count = 0;
        hours.forEach((hour, index) => {
            if (hours.length - 1 !== index && parseTime(hour) + 1 === parseTime(hours[index + 1])) {
                count++
            }
        });
            addReservationInputsButton.style.display = count <= 1 ? "none" : "block"
            loader.classList.add("loader-hidden")
    }

    const inputDate = document.querySelector("#reserveForm input[type=\"date\"]");
    inputDate.addEventListener("change", async (el) => {
        await init();
    })

    const clearErrors = () => {
        generalError.style.display = "none";
        noSlotsError.style.display = "none";
    }

    const reservationInputsWrapper = document.querySelector(
        ".reservation-inputs"
    );

    const addReservationInputsButton = document.querySelector(".add-inputs");
    addReservationInputsButton.style.display = "none";


    const createReservationInputs = () => {
        const reservationInputs = document.importNode(
            document.querySelector("#reservation-inputs-template").content,
            true
        )

        const reservationId = reservationInputs.querySelector(".reservation-input")
        reservationId.setAttribute("data-reservation-id", state.inputCount);
        const reservationIdEl = reservationId.getAttribute("data-reservation-id")

        // if (showLabel) {
        //     reservationInputs.querySelectorAll("label").forEach((label) => {
        //         label.remove()
        //     })
        //
        //     reservationInputs.querySelectorAll("br").forEach((br) => {
        //         br.remove()
        //     })
        // }

        const startTimeButton = reservationInputs.querySelector("#start-time");
        const endTimeButton = reservationInputs.querySelector("#end-time");

        const startTimeMenu = reservationInputs.querySelector(".start-time ul");
        const endTimeMenu = reservationInputs.querySelector(".end-time ul");

        const priceEl = reservationInputs.querySelector("#price p span");

        const removeInputs = reservationInputs.querySelector(".btn-delete");


        removeInputs.addEventListener("click", (el) => {
            if (endTimeButton.textContent !== "--:00" && startTimeButton.textContent !== "--:00") {
                // Remove reservation
                state.reservations.forEach((time, index) => {
                    if (time.startTime === startTimeButton.textContent && time.endTime === endTimeButton.textContent) {
                        state.reservations.splice(index, 1)
                    }
                });

                // hours = availableHoursReset(hours, startTimeButton.textContent, endTimeButton.textContent)

                // Update Reservation Summary Content
                createReservationSummary();

            }
            refreshButton();

            // Remove Input Elements from Dom
            el.target.parentElement.parentElement.remove();

            // Enable Add Inputs Button
            state.addInputsSwitch = true;
        })

        const startTimeItemOnClick = (e) => {
            startTimeButton.textContent = e.target.textContent;

            if (state.reservations) {
                state.reservations = state.reservations.filter((hour, index) => {
                    if (hour.id === reservationIdEl) {
                        return;
                    }
                    return hour;
                })
                // hours = availableHoursReset(hours, startTimeButton.textContent, endTimeButton.textContent)
            }

            startTimeButton.parentElement.querySelector("input").value = parseTime(e.target.textContent);
            endTimeButton.textContent = "--:00";
            endTimeButton.removeAttribute("disabled");
            priceEl.textContent = 0;
        }
        startTimeButton.addEventListener("click", () => {
            let reservations = reservationsExcept(startTimeButton.textContent);
            createDropdownItems(getStartTimeFilteredHours(reservations), startTimeMenu, startTimeItemOnClick);
        })

        const endTimeItemOnClick = async (e) => {
            const {success, price} = await fetchHoursPrice(state.selectedDate,
                parseTime(startTimeButton.textContent), parseTime(e.target.textContent));

            if (!success) {
                console.log("Something went wrong")
                return
            }

            priceEl.textContent = price

            if (state.reservations) {
                if (state.reservations.length !== 0) {
                    let trigger = false;
                    state.reservations.forEach((hour) => {
                        if (hour.id === reservationIdEl) {
                            trigger = true
                        }
                    })

                    if (trigger) {
                        state.reservations = state.reservations.map((hour) => {
                            if (hour.id === reservationIdEl) {
                                return {
                                    id: reservationIdEl,
                                    startTime: startTimeButton.textContent,
                                    endTime: e.target.textContent,
                                    price
                                }
                            }
                            return hour;
                        })
                    } else {
                        state.reservations.push({
                            id: reservationIdEl,
                            startTime: startTimeButton.textContent,
                            endTime: e.target.textContent,
                            price
                        })
                    }
                } else {
                    state.reservations.push({
                        id: reservationIdEl,
                        startTime: startTimeButton.textContent,
                        endTime: e.target.textContent,
                        price
                    })
                }

                endTimeButton.parentElement.querySelector('input').value = parseTime(e.target.textContent)

                refreshButton();
                createReservationSummary();

                // hours = hours.filter((hour) => {
                //     if (parseTime(hour) < parseTime(startTimeButton.textContent)) {
                //         return hour;
                //     } else if (parseTime(hour) > parseTime(e.target.textContent)){
                //         return hour;
                //     }
                //     return;
                // })
            }

            // console.log(state.reservations,reservationIdEl)

            endTimeButton.textContent = e.target.textContent;
        }
        endTimeButton.addEventListener("click", () => {
            let other_reservations = reservationsExcept(startTimeButton.textContent);
            let hours = getEndTimeFilteredHours(startTimeButton.textContent, other_reservations);

            createDropdownItems(hours, endTimeMenu, endTimeItemOnClick);
        })

        endTimeButton.textContent = "--:00";
        startTimeButton.textContent = "--:00";
        state.inputCount++;
        return reservationInputs;
    };

    let getEndTimeFilteredHours = (startTime, reservations = state.reservations) => {
        let hours = getAllDropdownHours();
        let stop = false;

        return hours.filter((hour) => {
            if (parseTime(hour) <= parseTime(startTime)) {
                return;
            } else if (isHourReserved(hour, false, reservations)) {
                stop = true;
                return;
            }

            if (!stop) {
                return hour;
            }
        });
    }

    let reservationsExcept = (start_hour) => {
        const all_reservations = state.reservations;
        return all_reservations.filter(function (reservation){
            if(reservation.startTime !== start_hour){
                return reservation;
            }
        });
    }

    let refreshButton = () => {
        updateLocalStorage()
        if (getStartTimeFilteredHours().length === 0) {
            addReservationInputsButton.style.display = "none";
        } else {
            addReservationInputsButton.style.display = "block";
        }
    };

    let updateLocalStorage = () => {

        if(getHallLocalStorage().date !== state.selectedDate) {
            updateHallLocalStorage('date', state.selectedDate)
        }

        let reservations = [];
        state.reservations.forEach(function(reservation){
            reservations.push({
                start: reservation.startTime,
                end: reservation.endTime
            })
        })
        updateHallLocalStorage('reservations', reservations)
    }

    const isHourAvailable = (int_hour = null, str_hour = null, start_only) => {
        let hour;
        if (int_hour) {
            hour = `${int_hour}:00`;
        }
        if (str_hour) {
            hour = str_hour;
        }
        if (start_only) {
            hour = `${parseTime(hour) + 1}:00`
        }

        return state.availableHours.indexOf(hour) !== -1;
    }

    let getStartTimeFilteredHours = (reservations = state.reservations) => {
        let hours = getAllDropdownHours();

        return hours.filter((hour) => {
            if (isHourReserved(hour, true, reservations)) {
                return;
            }
            if (hour === hours[hours.length - 1]) { // if last element
                return;
            }
            if (!isHourAvailable(null, hour, true)) {
                return;
            }

            return hour;
        });
    }


    /**
     * Decides whether an hour is reserved or not
     *
     * @param {string} hour
     * @param {boolean} start
     * @param reservations
     * @returns {boolean}
     */
    let isHourReserved = (hour, start, reservations = state.reservations) => {
        let parsed_hour = parseTime(hour);
        let reserved = false;
        reservations.forEach(function (reservation){
            if (start) {
                if (parsed_hour >= parseTime(reservation.startTime) && parsed_hour < parseTime(reservation.endTime)) {
                    reserved = true;
                }
            } else {
                if (parsed_hour > parseTime(reservation.startTime) && parsed_hour <= parseTime(reservation.endTime)) {
                    reserved = true;
                }
            }
        });
        if (state.availableHours.indexOf(hour) === -1) {
            reserved = true;
        }
        return reserved;
    }

    const getAllDropdownHours = () => {
        let first_hour = parseTime(state.availableHours[0]);
        let last_hour = parseTime(state.availableHours[state.availableHours.length - 1]);
        let allHours = [];
        for (let i = first_hour; i <= last_hour; i++) {
            allHours[i] = `${i}:00`;
        }
        return allHours;
    }

    const reservationSummary = document.querySelector(".summary-inner");
    const contentWrapper = reservationSummary.querySelector(".summary-reservation-content")

    const createReservationSummary = () => {
        contentWrapper.innerHTML = "";

        if (state.reservations.length === 0) {
            reservationSummary.querySelector("h6").style.display = "block"
            return;
        }

        reservationSummary.querySelector("h6").style.display = "none"

        const reservationSummaryTemplate = document.importNode(
            document.querySelector("#reservation-summary-template").content,
            true
        );

        const summaryItems = reservationSummaryTemplate.querySelector(".summary-items")
        reservationSummaryTemplate.querySelector(".summary-date").textContent = `VEN ${state.selectedDate}`;

        let total = 0;
        state.reservations.forEach((item) => {
            const summaryItem = document.createElement("li");
            const itemHours = document.createElement("p");
            const itemPrice = document.createElement("p");
            itemHours.textContent = `${item.startTime} - ${item.endTime}`
            itemPrice.textContent = `€ ${item.price}`;
            total += item.price

            summaryItem.append(itemHours, itemPrice)
            summaryItems.appendChild(summaryItem)
        })

        reservationSummaryTemplate.querySelector(".summary-total-price p").textContent = `€ ${total}`;

        reservationSummaryTemplate.querySelector(".summary-remove-button").addEventListener("click", async (el) => {
            await init();
        })

        contentWrapper.appendChild(reservationSummaryTemplate)

    }

    const clearSummary = () => {
        state.reservations = [];
        contentWrapper.innerHTML = "";
        reservationSummary.querySelector("h6").style.display = "block"
    }


    addReservationInputsButton.addEventListener("click", (el) => {
        let trigger = true;
        reservationInputsWrapper.querySelectorAll(".dropdown-toggle").forEach((el) => {
            if (el.textContent === "--:00") {
                trigger = false
            }
        })

        if (trigger) {
            const inputs = createReservationInputs();
            reservationInputsWrapper.appendChild(inputs);
        }

        // let availableTimes = false;
        // availableHours.forEach((time, index) => {
        //     if (availableHours.length - 1 !== index && parseTime(time) + 1 === parseTime(availableHours[index + 1])) {
        //         availableTimes = true
        //     }
        // })
        //
        //
        // if (availableHours.length !== 0 && availableTimes && reservations.length !== 0 && addInputsSwitch) {
        //     createReservationInputs(availableHours, true,reservations,selectedDate, selectedDate)
        // }
        // createReservationInputs(availableHours, true,reservations,selectedDate, selectedDate)
        // addInputsSwitch = false;
    })

    const changeDateInput = (date) => {
        inputDate.value = date
        inputDate.dispatchEvent(new Event('change'))
    }

    const fillReservationInput = (element, startTime, endTime) => {
        let startTimeButton = element.querySelector("#start-time");
        let endTimeButton = element.querySelector("#end-time");

        startTimeButton.dispatchEvent(new Event('click'));
        endTimeButton.dispatchEvent(new Event('click'));

        let startTimeDropdownMenu = startTimeButton.parentElement.querySelector(".dropdown-menu");
        let endTimeDropdownMenu = endTimeButton.parentElement.querySelector(".dropdown-menu");

        startTimeDropdownMenu
            .querySelectorAll('li')
            .forEach(function (element) {
                if (element.innerHTML === startTime) {
                    element.dispatchEvent(new Event('click'));
                }
            })

        endTimeDropdownMenu
            .querySelectorAll('li')
            .forEach(function (element) {
                if (element.innerHTML === endTime) {
                    element.dispatchEvent(new Event('click'));
                }
            })

        startTimeButton.innerHTML = startTime;
        endTimeButton.innerHTML = endTime;
    }

    const addReservationInput = (startTime, endTime, index, last_index) => {

        let checkExist = setInterval(function () {
            if (document.querySelector(`[data-reservation-id='${index}']`)) {
                let element = document.querySelector(`[data-reservation-id='${index}']`).parentElement;
                fillReservationInput(element, startTime, endTime);
                clearInterval(checkExist);
                if(index < last_index){
                    addReservationInputsButton.click();
                }
            }
        }, 100);
    }

    const resetLastData = () => {
        if (getHallLocalStorage()) {
            let reservations = getHallLocalStorage().reservations;

            if (reservations.length === 0) {
                return;
            }

            changeDateInput(getHallLocalStorage().date);

            for (let i = 0; i < reservations.length; i++) {
                addReservationInput(reservations[i].start, reservations[i].end, i, reservations.length - 1)
            }

            if (getHallLocalStorage().payment_method) {
                const checkbox = document.querySelector(`input[name='payment_method'][value='${getHallLocalStorage().payment_method}']`);
                checkbox.checked = true;
                checkbox.dispatchEvent(new Event('change')); // to update the input field
            }

        }
    }


    document.querySelectorAll("div.payment-methods input[name='payment_method']")
        .forEach(function (payment_method) {

            payment_method.addEventListener('change', function (e) {
                document.querySelector("#reserveForm input[name='payment_method']").value = e.target.getAttribute('value')
                updateHallLocalStorage('payment_method', e.target.value)
            });
        });

    document.querySelector("#terms")
        .addEventListener('change', function (e) {
            document.querySelector('#reserveForm input[name=\'accept_terms\']').value = e.target.checked
        });

    submitButton
        .addEventListener('click', function () {
            form.submit()
        })

    $(document).on("submit", "#reserveForm", function(e)
    {
        e.preventDefault();
        form.find('span.error').html('').hide();
        const stripe = Stripe(process.env.MIX_STRIPE_KEY);
        const loaderClassName = "hall_reservation__loader";

        appendLoader(loaderClassName);

        $.ajax({
            url: this.action,
            type: this.method,
            dataType: 'JSON',
            contentType: false,
            processData: false,
            data: new FormData(this),
            error: function (jqXHR, statusText, error) {
                removeLoader(loaderClassName);
                console.log(jqXHR.responseJSON);
                if(jqXHR.status == 422){
                    const errors = [];
                    $.each(jqXHR.responseJSON.errors, function (key, message){
                        errors.push(message.join("\n"))
                    });
                    $('span.error[for="hall_reservation_errors"]').html(errors.join("\n")).show();
                } else {
                    toastr.error(jqXHR.responseJSON.message)
                }
            },
            success: function (response) {
                removeLoader(loaderClassName);
                if(response.message) {
                    toastr.success(response.message)

                } else if(response.id) {

                    return stripe.redirectToCheckout({ sessionId: response.id });
                }
            },
        });
    });

    resetLastData();
});


